import React from "react";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";

interface RecaptchaProviderProps {
    children: React.ReactNode;
}

const RecaptchaProvider: React.FC<RecaptchaProviderProps> = ({ children }) => {
    if (!process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY) return null;

    return (
        <GoogleReCaptchaProvider
            reCaptchaKey={process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY}
            language="es"
        >
            {children}
        </GoogleReCaptchaProvider>
    );
};

export default RecaptchaProvider;
