"use client";
import React from "react";
import { motion } from "framer-motion";
import { Button } from "@nextui-org/button";
import Logo from "@/components/Logo";
import Typography from "@/components/Typography";

const animation = {
    exit: { opacity: 0 },
    initial: {
        opacity: 0,
        scale: 0.1,
    },
    animate: { opacity: 1, scale: 1 },
};

interface HeaderProps {
    onPress: () => void;
}

const Header: React.FC<HeaderProps> = ({ onPress }) => {
    return (
        <section id="header" className="mx-auto">
            <div className="flex flex-col items-center w-full p-10">
                <motion.div
                    {...animation}
                    transition={{ duration: 0.5, delay: 0.2 }}
                >
                    <Logo className="h-32 xl:h-48" />
                </motion.div>
                <div className="flex flex-col justify-center w-full py-10 xl:py-20 max-w-5xl">
                    <motion.div
                        {...animation}
                        transition={{ duration: 0.5, delay: 0.4 }}
                    >
                        <Typography
                            variant="h1"
                            className="text-white text-center text-5xl xl:text-7xl font-black"
                        >
                            Inscríbete como Artesano
                        </Typography>
                    </motion.div>
                    <motion.div
                        {...animation}
                        transition={{ duration: 0.5, delay: 0.6 }}
                    >
                        <Typography
                            variant="h2"
                            className="text-white text-center py-2.5 text-2xl xl:text-4xl font-bold"
                        >
                            Únete a Nuestros Mercados Medievales
                        </Typography>
                    </motion.div>
                    <motion.div
                        {...animation}
                        transition={{ duration: 0.5, delay: 0.8 }}
                    >
                        <Typography
                            variant="body1"
                            className="text-white text-center py-2.5 text-lg xl:text-2xl font-normal"
                        >
                            Imagina <strong>vender tus productos</strong> en un entorno
                            encantador y auténtico, donde{" "}
                            <strong>la magia del Medievo</strong> cobra vida. Con
                            nosotros, <strong>destacarás y cautivarás</strong> a un
                            público apasionado por la autenticidad.{" "}
                            <strong>Tu arte brillará</strong> con todo su esplendor en{" "}
                            <strong>un escenario único</strong>, donde la historia se
                            entrelaza con el presente.
                        </Typography>
                    </motion.div>
                    <motion.div
                        {...animation}
                        transition={{ duration: 0.5, delay: 1 }}
                    >
                        <Typography
                            variant="h3"
                            className="text-white text-center pt-5 text-xl xl:text-3xl font-semibold"
                        >
                            ¡Descubre nuevas oportunidades <strong>hoy mismo</strong>!
                        </Typography>
                    </motion.div>
                </div>
                <motion.div
                    {...animation}
                    transition={{ duration: 0.5, delay: 1.2 }}
                >
                    <Button
                        size="lg"
                        radius="full"
                        onPress={onPress}
                        className="bg-lineal-gradient text-white py-8 xl:py-9 px-16 xl:px-20 text-lg xl:text-2xl font-bold uppercase drop-shadow-2xl"
                    >
                        Únete ahora
                    </Button>
                </motion.div>
            </div>
        </section>
    );
};

export default Header;
