import React from "react";
import { FormikProps, FormikValues } from "formik";
import { motion } from "framer-motion";
import { Input } from "@nextui-org/input";
import { useFormDirection } from "@/modules/persistent-states";

const Step1: React.ComponentType<
    FormikProps<FormikValues> & {
        currentStepIndex: number;
    }
> = ({ values, touched, errors, handleChange, handleBlur }) => {
    const [direction] = useFormDirection();

    let animation;

    if (direction === "none") {
        animation = {
            exit: { opacity: 0 },
            initial: {
                opacity: 0,
            },
            animate: { opacity: 1 },
        };
    }

    if (direction === "ltr") {
        animation = {
            initial: {
                x: "-50%",
            },
            animate: { x: 0 },
        };
    }

    return (
        <motion.div {...animation} transition={{ duration: 0.5 }}>
            <div className="flex justify-center items-center flex-col w-full">
                <div className="flex justify-center items-center flex-col gap-3 w-full">
                    <div className="flex w-full lg:h-[75px]">
                        <Input
                            name="full_name"
                            type="text"
                            label="Nombre y Apellidos"
                            isRequired
                            value={values?.full_name}
                            errorMessage={
                                <>{touched?.full_name && errors?.full_name}</>
                            }
                            color={
                                touched?.full_name && errors?.full_name
                                    ? "danger"
                                    : "default"
                            }
                            onChange={handleChange}
                            onBlur={handleBlur}
                            classNames={{
                                label: "font-bold",
                                errorMessage: "font-bold text-rose-200",
                            }}
                        />
                    </div>
                    <div className="flex w-full lg:h-[75px]">
                        <Input
                            name="email"
                            type="text"
                            label="Correo Electrónico"
                            isRequired
                            value={values?.email}
                            errorMessage={
                                <>{touched?.email && errors?.email}</>
                            }
                            color={
                                touched?.email && errors?.email
                                    ? "danger"
                                    : "default"
                            }
                            onChange={handleChange}
                            onBlur={handleBlur}
                            classNames={{
                                label: "font-bold",
                                errorMessage: "font-bold text-rose-200",
                            }}
                        />
                    </div>
                    <div className="flex w-full lg:h-[75px]">
                        <Input
                            name="phone"
                            type="text"
                            label="Teléfono"
                            isRequired
                            value={values?.phone}
                            errorMessage={
                                <>{touched?.phone && errors?.phone}</>
                            }
                            color={
                                touched?.phone && errors?.phone
                                    ? "danger"
                                    : "default"
                            }
                            onChange={handleChange}
                            onBlur={handleBlur}
                            classNames={{
                                label: "font-bold",
                                errorMessage: "font-bold text-rose-200",
                            }}
                        />
                    </div>
                    <div className="flex w-full lg:h-[75px]">
                        <Input
                            name="city"
                            type="text"
                            label="¿De donde vienes? (Ciudad, Región)"
                            value={values?.city}
                            errorMessage={<>{touched?.city && errors?.city}</>}
                            color={
                                touched?.city && errors?.city
                                    ? "danger"
                                    : "default"
                            }
                            onChange={handleChange}
                            onBlur={handleBlur}
                            classNames={{
                                label: "font-bold",
                                errorMessage: "font-bold text-rose-200",
                            }}
                        />
                    </div>
                    <div className="flex w-full lg:h-[75px]">
                        <Input
                            name="website"
                            type="text"
                            label="Sitio Web"
                            placeholder="https://example.com"
                            value={values?.website}
                            errorMessage={
                                <>{touched?.website && errors?.website}</>
                            }
                            color={
                                touched?.website && errors?.website
                                    ? "danger"
                                    : "default"
                            }
                            onChange={handleChange}
                            onBlur={handleBlur}
                            classNames={{
                                label: "font-bold",
                                errorMessage: "font-bold text-rose-200",
                            }}
                        />
                    </div>
                </div>
            </div>
        </motion.div>
    );
};

export default Step1;
