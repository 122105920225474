"use client";
import React from "react";
import ReactGA from "react-ga";
import { useDisclosure } from "@nextui-org/modal";
import { withRecaptcha } from "@/lib/recaptcha";
import Header from "@/sections/Header";
import SectionOne from "@/sections/SectionOne";
import SectionTwo from "@/sections/SectionTwo";
import SectionThree from "@/sections/SectionThree";
import SectionFour from "@/sections/SectionFour";
import SectionFive from "@/sections/SectionFive";
import SectionSix from "@/sections/SectionSix";
import SectionSeven from "@/sections/SectionSeven";
import Footer from "@/sections/Footer";
import ModalForm from "@/sections/ModalForm";

const isProduction = process.env.NODE_ENV === "production";

const Home = withRecaptcha(() => {
    const { isOpen, onOpen, onOpenChange } = useDisclosure();

    React.useEffect(() => {
        if (isProduction) {
            ReactGA.initialize(
                process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_USER_ID || "",
            );
        }
    }, []);

    return (
        <main className="h-full	m-0 p-0 box-border bg-black">
            <div className="bg-radial-gradient-top">
                <Header onPress={onOpen} />
            </div>
            <div className="bg-radial-gradient-center">
                <SectionOne />
                <SectionTwo />
                <SectionThree />
            </div>
            <div className="bg-radial-gradient-left">
                <SectionFour onPress={onOpen} />
            </div>
            <div className="bg-radial-gradient-right">
                <SectionFive />
            </div>
            <div className="bg-radial-gradient-center">
                <SectionSix />
            </div>
            <div className="bg-radial-gradient-center">
                <SectionSeven />
            </div>
            <div className="bg-radial-gradient-bottom">
                <Footer onPress={onOpen} />
            </div>
            <ModalForm isOpen={isOpen} onOpenChange={onOpenChange} />
        </main>
    );
});

export default Home;
