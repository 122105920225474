"use client";
import React from "react";
import { motion } from "framer-motion";
import { Image } from "@nextui-org/image";
import { Button } from "@nextui-org/button";
import FsLightbox from "fslightbox-react";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import Typography from "@/components/Typography";
import posters from "@/data/posters.json";

const animation = {
    exit: { opacity: 0 },
    initial: {
        opacity: 0,
        scale: 0.1,
    },
    animate: { opacity: 1, scale: 1 },
};

interface SectionFourProps {
    onPress: () => void;
}

const SectionFour: React.FC<SectionFourProps> = ({ onPress }) => {
    const [lightboxController, setLightboxController] = React.useState({
        toggler: false,
        slide: 1,
    });

    const openLightboxOnSlide = (number: number) => {
        setLightboxController({
            toggler: !lightboxController.toggler,
            slide: number,
        });
    };

    return (
        <section id="section-four" className="container mx-auto my-32 xl:mt-72">
            <motion.div
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                transition={{ duration: 2 }}
            >
                <div className="flex flex-col lg:flex-row justify-center items-center py-5 px-2">
                    <div className="flex w-full xl:w-3/4 flex-col">
                        <motion.div
                            {...animation}
                            transition={{ duration: 0.5, delay: 0.4 }}
                        >
                            <Typography
                                variant="h2"
                                className="text-white text-center text-4xl xl:text-7xl font-black"
                            >
                                Tenemos el espacio perfecto para ti
                            </Typography>
                            <Typography
                                variant="body1"
                                className="text-white text-center pt-2.5 text-lg xl:text-2xl font-normal"
                            >
                                Podrías estar perdiendo la oportunidad de
                                obtener buenas ganancias.
                            </Typography>
                            <Typography
                                variant="body1"
                                className="text-white text-center text-xl xl:text-3xl font-semibold"
                            >
                                Mira nuestros próximos eventos y súmate.
                            </Typography>
                        </motion.div>
                    </div>
                </div>
                <div className="flex flex-col lg:flex-row justify-center items-center p-5 gap-10">
                    <div className="flex w-full flex-col max-w-[450px]">
                        <motion.div
                            {...animation}
                            transition={{ duration: 0.5, delay: 0.4 }}
                        >
                            <ResponsiveMasonry
                                columnsCountBreakPoints={{
                                    350: 1,
                                    // 750: 2,
                                    // 900: 3,
                                }}
                            >
                                <Masonry gutter="20px">
                                    {posters.map((item) => {
                                        return (
                                            <Image
                                                key={item.id}
                                                alt={item.title}
                                                src={item.image}
                                                className="drop-shadow-2xl"
                                                onClick={() =>
                                                    openLightboxOnSlide(item.id)
                                                }
                                            />
                                        );
                                    })}
                                </Masonry>
                            </ResponsiveMasonry>
                            <FsLightbox
                                toggler={lightboxController.toggler}
                                slide={lightboxController.slide}
                                sources={posters.map((item) => item.image)}
                                disableBackgroundClose
                                exitFullscreenOnClose
                            />
                        </motion.div>
                    </div>
                </div>
                <div className="flex flex-col lg:flex-row justify-center items-center py-10 xl:py-24 gap-10">
                    <motion.div
                        {...animation}
                        transition={{ duration: 0.5, delay: 1.2 }}
                    >
                        <Button
                            size="lg"
                            radius="full"
                            onPress={onPress}
                            className="bg-lineal-gradient text-white py-8 xl:py-9 px-12 xl:px-20 text-lg xl:text-2xl font-bold uppercase drop-shadow-2xl"
                        >
                            Únete ya, no esperes más
                        </Button>
                    </motion.div>
                </div>
            </motion.div>
        </section>
    );
};

export default SectionFour;
