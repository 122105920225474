"use client";
import React from "react";
import ReactGA from "react-ga";
import * as Yup from "yup";
import { FormikValues } from "formik";
import { FormikWizard } from "formik-wizard-form";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { useDisclosure } from "@nextui-org/modal";
import { Button } from "@nextui-org/button";
import { useFormDirection } from "@/modules/persistent-states";
import SuccessMessage from "@/sections/SuccessMessage";
import ErrorMessage from "@/sections/ErrorMessage";
import Step1 from "@/forms/step1-form";
import Step2 from "@/forms/step2-form";
import Step3 from "@/forms/step3-form";

interface EnrollmentFormProps {
    onClose: () => void;
}

const EnrollmentForm: React.FC<EnrollmentFormProps> = ({ onClose }) => {
    const [loading, setLoading] = React.useState<boolean>(false);
    const { executeRecaptcha } = useGoogleReCaptcha(); // Recaptcha
    const { isOpen, onOpen, onOpenChange } = useDisclosure();
    const [status, setStatus] = React.useState<string>("");
    const [, setDirection] = useFormDirection();

    // Form initial values
    const initialValues = {
        full_name: "",
        email: "",
        phone: "",
        city: "",
        website: "",
        business_name: "",
        category: "",
        business_type: "",
        description: "",
        space: "",
        rc: undefined,
        physical_store: undefined,
        crafts_workshop: undefined,
        experience: undefined,
        experience_years: "",
        accept_policies: false,
    };

    const validatorSchemeStep1 = Yup.object().shape({
        full_name: Yup.string().required("Introduzca su nombre completo."),
        email: Yup.string()
            .email("Introduzca una dirección de correo válida.")
            .required("Introduzca su correo electrónico."),
        phone: Yup.string().required("Introduzca su número de teléfono."),
        city: Yup.string(),
        website: Yup.string(),
    });

    const validatorSchemeStep2 = Yup.object().shape({
        business_name: Yup.string().required(
            "Introduzca el nombre de su negocio.",
        ),
        category: Yup.string().required("Seleccione una categoría."),
        description: Yup.string(),
        business_type: Yup.string(),
    });

    const validatorSchemeStep3 = Yup.object().shape({
        space: Yup.string().required(
            "Especifique la cantidad de espacio que necesita.",
        ),
        physical_store: Yup.boolean(),
        crafts_workshop: Yup.boolean(),
        rc: Yup.boolean(),
        experience: Yup.boolean(),
        experience_years: Yup.string(),
    });

    const validationSchemas = [
        validatorSchemeStep1,
        validatorSchemeStep2,
        validatorSchemeStep3,
    ];

    // Create an event handler, so you can call the verification on form submit
    const handleOnSubmit = async (values: FormikValues) => {
        setLoading(true);

        if (executeRecaptcha) {
            const token = await executeRecaptcha("REGISTRATION_FORM");

            try {
                const response = await fetch("/api/", {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        fullName: values.full_name,
                        email: values.email,
                        phone: values.phone,
                        city: values.city,
                        website: values.website,
                        businessName: values.business_name,
                        category: values.category,
                        businessType: values.business_type,
                        description: values.description,
                        space: values.space,
                        rc: values.rc,
                        physicalStore: values.physical_store,
                        craftsWorkshop: values.crafts_workshop,
                        experience: values.experience,
                        experienceYears: values.experience_years,
                        "g-recaptcha-response": token,
                    }),
                });

                if (response.ok) {
                    ReactGA.event({
                        category: "Artesano",
                        action: "Se registro un nuevo artesano",
                        label: values.full_name,
                    });

                    setStatus("success");
                    onOpen();
                } else {
                    setStatus("error");
                    onOpen();
                    console.error(
                        "Error en la solicitud:",
                        response.statusText,
                    );
                }
                setLoading(false);
            } catch (error) {
                setLoading(false);
                setStatus("error");
                onOpen();
                console.error("Error al enviar el formulario:", error);
            }
        }
    };

    return (
        <>
            <FormikWizard
                enableReinitialize
                initialValues={initialValues}
                onSubmit={handleOnSubmit}
                validateOnNext
                activeStepIndex={0}
                steps={[
                    {
                        component: Step1,
                        validationSchema: validatorSchemeStep1,
                    },
                    {
                        component: Step2,
                        validationSchema: validatorSchemeStep2,
                    },
                    {
                        component: Step3,
                        validationSchema: validatorSchemeStep3,
                    },
                ]}
            >
                {({
                    values,
                    currentStepIndex,
                    renderComponent,
                    handlePrev,
                    handleNext,
                    isFirstStep,
                    isLastStep,
                    setFieldError,
                    setFieldTouched,
                }) => {
                    return (
                        <>
                            <div>{renderComponent()}</div>
                            <div className="flex w-full justify-between">
                                <Button
                                    size="lg"
                                    radius="full"
                                    onClick={() => {
                                        setDirection("ltr");
                                        handlePrev();
                                    }}
                                    style={{
                                        visibility: isFirstStep
                                            ? "hidden"
                                            : "visible",
                                    }}
                                    className="bg-lineal-gradient text-white py-4 px-6 lg:px-8 text-sm lg:text-md font-bold uppercase drop-shadow-2xl"
                                >
                                    Anterior
                                </Button>
                                <Button
                                    size="lg"
                                    radius="full"
                                    isDisabled={
                                        isLastStep && !values.accept_policies
                                    }
                                    isLoading={loading}
                                    onClick={() => {
                                        setDirection("rtl");
                                        const currentValidationSchema =
                                            validationSchemas[
                                                currentStepIndex as number
                                            ];

                                        currentValidationSchema
                                            .validate(values, {
                                                abortEarly: false,
                                            })
                                            .then(() => {
                                                handleNext();
                                            })
                                            .catch(
                                                (
                                                    validationErrors: Yup.ValidationError,
                                                ) => {
                                                    validationErrors.inner.forEach(
                                                        (error) => {
                                                            void setFieldTouched(
                                                                error?.path as string,
                                                                true,
                                                            ).then(() => {
                                                                setFieldError(
                                                                    error?.path as string,
                                                                    error?.message,
                                                                );
                                                            });
                                                        },
                                                    );
                                                },
                                            );
                                    }}
                                    className="bg-lineal-gradient text-white py-4 px-6 lg:px-8 text-sm lg:text-md font-bold uppercase drop-shadow-2xl"
                                >
                                    {isLastStep ? "Registrarme" : "Siguiente"}
                                </Button>
                            </div>
                        </>
                    );
                }}
            </FormikWizard>
            <SuccessMessage
                isOpen={isOpen && status === "success"}
                onOpenChange={onOpenChange}
                onCloseParent={onClose}
            />
            <ErrorMessage
                isOpen={isOpen && status === "error"}
                onOpenChange={onOpenChange}
                onCloseParent={onClose}
            />
        </>
    );
};

export default EnrollmentForm;
