"use client";
import React from "react";
import { motion } from "framer-motion";
import { Image } from "@nextui-org/image";
import FsLightbox from "fslightbox-react";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import Typography from "@/components/Typography";
import events from "@/data/events.json";

const animation = {
    exit: { opacity: 0 },
    initial: {
        opacity: 0,
        scale: 0.1,
    },
    animate: { opacity: 1, scale: 1 },
};

const SectionSix: React.FC = () => {
    const [lightboxController, setLightboxController] = React.useState({
        toggler: false,
        slide: 1,
    });

    const openLightboxOnSlide = (number: number) => {
        setLightboxController({
            toggler: !lightboxController.toggler,
            slide: number,
        });
    };

    return (
        <section id="section-six" className="container mx-auto my-32 xl:mt-72">
            <motion.div
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                transition={{ duration: 2 }}
            >
                <div className="flex flex-col lg:flex-row justify-center items-center py-5 px-2.5">
                    <div className="flex w-full xl:w-3/4 flex-col">
                        <motion.div
                            {...animation}
                            transition={{ duration: 0.5, delay: 0.4 }}
                        >
                            <Typography
                                variant="h2"
                                className="text-white text-center text-4xl xl:text-7xl font-black"
                            >
                                Recuerdos de nuestros eventos pasados
                            </Typography>
                            <Typography
                                variant="body1"
                                className="text-white text-center pt-2.5 text-lg xl:text-2xl font-normal"
                            >
                                Explora estas imágenes cautivadoras que capturan
                                la esencia de nuestros eventos anteriores. Cada
                                fotografía nos transporta a momentos de
                                autenticidad, diversión y creatividad
                                compartidos en nuestros mercados medievales.
                                Estos recuerdos son un testimonio de la magia
                                que se vive en cada uno de nuestros encuentros,
                                y esperamos que te inspiren a unirte a nosotros
                                en futuras aventuras medievales.
                            </Typography>
                        </motion.div>
                    </div>
                </div>
                <div className="flex flex-col lg:flex-row justify-center items-center p-5 gap-10">
                    <div className="flex w-full flex-col">
                        <motion.div
                            {...animation}
                            transition={{ duration: 0.5, delay: 0.4 }}
                        >
                            <ResponsiveMasonry
                                columnsCountBreakPoints={{
                                    350: 1,
                                    900: 3,
                                }}
                            >
                                <Masonry gutter="20px">
                                    {events.map((item) => {
                                        return (
                                            <Image
                                                isZoomed
                                                key={item.id}
                                                alt={item.title}
                                                src={item.image}
                                                className="drop-shadow-2xl"
                                                onClick={() =>
                                                    openLightboxOnSlide(item.id)
                                                }
                                            />
                                        );
                                    })}
                                </Masonry>
                            </ResponsiveMasonry>
                            <FsLightbox
                                toggler={lightboxController.toggler}
                                slide={lightboxController.slide}
                                sources={events.map((item) => item.image)}
                                disableBackgroundClose
                                exitFullscreenOnClose
                            />
                        </motion.div>
                    </div>
                </div>
            </motion.div>
        </section>
    );
};

export default SectionSix;
