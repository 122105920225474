"use client";
import React from "react";
import { motion } from "framer-motion";
import Marquee from "react-fast-marquee";
import Typography from "@/components/Typography";
import testimonials from "@/data/testimonials.json";
import Testimonials from "@/components/Testimonials";

const animation = {
    exit: { opacity: 0 },
    initial: {
        opacity: 0,
        scale: 0.1,
    },
    animate: { opacity: 1, scale: 1 },
};

const SectionFive: React.FC = () => {
    return (
        <section id="section-five" className="my-32 xl:mt-72">
            <motion.div
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                transition={{ duration: 2 }}
            >
                <div className="container mx-auto flex flex-col lg:flex-row justify-center items-center py-5 px-2">
                    <div className="flex w-full xl:w-3/4 flex-col">
                        <motion.div
                            {...animation}
                            transition={{ duration: 0.5, delay: 0.4 }}
                        >
                            <Typography
                                variant="h2"
                                className="text-white text-center text-4xl xl:text-7xl font-black"
                            >
                                ¿Qué dicen nuestros artesanos?
                            </Typography>
                            <Typography
                                variant="body1"
                                className="text-white text-center pt-2.5 text-lg xl:text-2xl font-normal"
                            >
                                Sumérgete en las experiencias compartidas por
                                talentosos artesanos que han sido parte de
                                nuestros eventos medievales. Descubre cómo
                                nuestros eventos han enriquecido sus vidas y
                                brindado oportunidades únicas para mostrar su
                                arte. Sus historias son un testimonio de la
                                autenticidad y el impacto positivo que
                                ofrecemos.
                            </Typography>
                        </motion.div>
                    </div>
                </div>
                <div className="flex flex-col lg:flex-row justify-center items-center py-20">
                    <div className="flex w-full flex-col items-center overflow-hidden">
                        <motion.div
                            {...animation}
                            transition={{ duration: 0.5, delay: 0.4 }}
                        >
                            <Marquee pauseOnHover autoFill speed={15}>
                                {testimonials.map((item) => {
                                    return (
                                        <Testimonials key={item.id} {...item} />
                                    );
                                })}
                            </Marquee>
                        </motion.div>
                    </div>
                </div>
            </motion.div>
        </section>
    );
};

export default SectionFive;
