import React from "react";

export type FontFamilies = "montserrat";

export type TypographyAs =
    | "h1"
    | "h2"
    | "h3"
    | "h4"
    | "h5"
    | "h6"
    | "span"
    | "p";

export type TypographyVariants =
    | "h1"
    | "h2"
    | "h3"
    | "h4"
    | "h5"
    | "h6"
    | "sub1"
    | "sub2"
    | "sub3"
    | "sub4"
    | "sub5"
    | "body1"
    | "body2"
    | "cap"
    | "btn"
    | "ovl";

export type TypographyProps = {
    as?: TypographyAs;
    variant: TypographyVariants;
    variantMapping?: Exclude<
        { [key in TypographyProps["variant"]]: keyof JSX.IntrinsicElements },
        "variantMapping"
    >;
    fontFamily?: FontFamilies;
    className?: string;
    children: React.ReactNode | string;
};

export const VARIANT_MAPPING: {
    [key in TypographyProps["variant"]]: keyof JSX.IntrinsicElements;
} = {
    h1: "h1",
    h2: "h2",
    h3: "h3",
    h4: "h4",
    h5: "h5",
    h6: "h6",
    sub1: "h2",
    sub2: "h3",
    sub3: "h4",
    sub4: "h5",
    sub5: "h6",
    body1: "p",
    body2: "p",
    cap: "span",
    btn: "span",
    ovl: "p",
};

export const DEFAULT_FONT_FAMILY = "montserrat";

const Typography: React.FC<TypographyProps> = ({
    as,
    variant,
    variantMapping,
    children,
    className,
    fontFamily = DEFAULT_FONT_FAMILY,
}) => {
    const Tag =
        as ||
        (variantMapping && variantMapping[variant]) ||
        VARIANT_MAPPING[variant];

    return (
        <Tag
            className={`font-${fontFamily} text-${fontFamily}-${variant} text-neutral-0 font-${fontFamily}-${variant} leading-${fontFamily}-${variant} ${
                className ? className : ""
            } ${variant === "ovl" ? "uppercase" : ""}`}
        >
            {children}
        </Tag>
    );
};

export default Typography;
