import React from "react";
import { FormikProps, FormikValues } from "formik";
import { motion } from "framer-motion";
import { Input } from "@nextui-org/input";
import { Radio, RadioGroup } from "@nextui-org/radio";
import { Checkbox } from "@nextui-org/checkbox";
import { useFormDirection } from "@/modules/persistent-states";

const Step3: React.ComponentType<
    FormikProps<FormikValues> & {
        currentStepIndex: number;
    }
> = ({ values, touched, errors, handleChange, handleBlur, setFieldValue }) => {
    const [direction] = useFormDirection();

    let animation;

    if (direction === "ltr") {
        animation = {
            initial: {
                x: "-50%",
            },
            animate: { x: 0 },
        };
    }

    if (direction === "rtl") {
        animation = {
            initial: {
                x: "50%",
            },
            animate: { x: 0 },
        };
    }

    return (
        <motion.div {...animation} transition={{ duration: 0.5 }}>
            <div className="flex justify-center items-center flex-col w-full">
                <div className="flex justify-center items-center flex-col gap-3 w-full">
                    <div className="flex w-full lg:h-[75px]">
                        <Input
                            name="space"
                            type="text"
                            label="¿Cuantos metros/lineales requiere?"
                            value={values?.space}
                            isRequired
                            errorMessage={
                                <>{touched?.space && errors?.space}</>
                            }
                            color={
                                touched?.space && errors?.space
                                    ? "danger"
                                    : "default"
                            }
                            onChange={handleChange}
                            onBlur={handleBlur}
                            classNames={{
                                label: "font-bold",
                                errorMessage: "font-bold text-rose-200",
                            }}
                        />
                    </div>
                    <div className="flex flex-col lg:flex-row w-full lg:h-[75px]">
                        <RadioGroup
                            label="¿Tienes tienda física?"
                            orientation="horizontal"
                            name="physical_store"
                            isRequired
                            value={values?.physical_store || "false"}
                            errorMessage={
                                <>
                                    {touched?.physical_store &&
                                        errors?.physical_store}
                                </>
                            }
                            color={
                                touched?.physical_store &&
                                errors?.physical_store
                                    ? "danger"
                                    : "default"
                            }
                            onChange={handleChange}
                            onBlur={handleBlur}
                            classNames={{
                                base: "basis-1/2",
                                label: "font-bold !text-white",
                                errorMessage: "font-bold text-rose-200",
                            }}
                        >
                            <Radio value="false" color="danger">
                                No
                            </Radio>
                            <Radio value="true" color="danger">
                                Si
                            </Radio>
                        </RadioGroup>
                        <RadioGroup
                            label="¿Tienes taller?"
                            orientation="horizontal"
                            name="crafts_workshop"
                            isRequired
                            value={values?.crafts_workshop || "false"}
                            errorMessage={
                                <>
                                    {touched?.crafts_workshop &&
                                        errors?.crafts_workshop}
                                </>
                            }
                            color={
                                touched?.crafts_workshop &&
                                errors?.crafts_workshop
                                    ? "danger"
                                    : "default"
                            }
                            onChange={handleChange}
                            onBlur={handleBlur}
                            classNames={{
                                base: "basis-1/2",
                                label: "font-bold !text-white",
                                errorMessage: "font-bold text-rose-200",
                            }}
                        >
                            <Radio value="false" color="danger">
                                No
                            </Radio>
                            <Radio value="true" color="danger">
                                Si
                            </Radio>
                        </RadioGroup>
                    </div>
                    <div className="flex flex-col lg:flex-row w-full lg:h-[75px]">
                        <RadioGroup
                            label="¿Tienes seguro de Responsabilidad Civil?"
                            orientation="horizontal"
                            name="rc"
                            isRequired
                            value={values?.rc || "false"}
                            errorMessage={<>{touched?.rc && errors?.rc}</>}
                            color={
                                touched?.rc && errors?.rc ? "danger" : "default"
                            }
                            onChange={handleChange}
                            onBlur={handleBlur}
                            classNames={{
                                label: "font-bold !text-white",
                                errorMessage: "font-bold text-rose-200",
                            }}
                        >
                            <Radio value="false" color="danger">
                                No
                            </Radio>
                            <Radio value="true" color="danger">
                                Si
                            </Radio>
                        </RadioGroup>
                    </div>
                    <div className="flex flex-row w-full mb-10 lg:mb-[0.75rem] lg:h-[75px]">
                        <RadioGroup
                            label="¿Tienes experiencias en eventos medievales?"
                            orientation="horizontal"
                            name="experience"
                            isRequired
                            value={values?.experience || "false"}
                            errorMessage={
                                <>{touched?.experience && errors?.experience}</>
                            }
                            color={
                                touched?.experience && errors?.experience
                                    ? "danger"
                                    : "default"
                            }
                            onChange={(e) => {
                                if (e.target.value === "false") {
                                    void setFieldValue("experience_years", "");
                                }
                                handleChange(e);
                            }}
                            onBlur={handleBlur}
                            classNames={{
                                base: "w-full h-[5rem]",
                                label: "font-bold !text-white",
                                errorMessage: "font-bold text-rose-200",
                            }}
                        >
                            <Radio value="false" color="danger">
                                No
                            </Radio>
                            <Radio value="true" color="danger">
                                Si
                            </Radio>
                            <Input
                                name="experience_years"
                                type="text"
                                size="md"
                                disabled={
                                    !values?.experience ||
                                    values?.experience === "false"
                                }
                                placeholder="¿Cuantos años?"
                                value={values?.experience_years}
                                errorMessage={
                                    <>
                                        {touched?.experience_years &&
                                            errors?.experience_years}
                                    </>
                                }
                                color={
                                    touched?.experience_years &&
                                    errors?.experience_years
                                        ? "danger"
                                        : "default"
                                }
                                onChange={handleChange}
                                onBlur={handleBlur}
                                classNames={{
                                    base: "w-36 ml-5",
                                    label: "font-bold text-md !text-white",
                                    errorMessage: "font-bold text-rose-200",
                                }}
                            />
                        </RadioGroup>
                    </div>
                    <div className="flex flex-row w-full mt-2 mb-4 lg:mb-[0.75rem]">
                        <Checkbox
                            name="accept_policies"
                            size="md"
                            color="danger"
                            value={values?.accept_policies}
                            onChange={(e) => {
                                handleChange(e);
                            }}
                            classNames={{
                                base: "flex items-start",
                                label: "font-semibold text-md !text-white mt-[-4px]",
                            }}
                        >
                            Acepto las{" "}
                            <a
                                href="https://akaeventos.com/politica-privacidad/"
                                target="_blank"
                                rel="noreferrer"
                                className="font-black hover:underline"
                            >
                                políticas de privacidad
                            </a>{" "}
                            de conformidad con el reglamento europeo de
                            privacidad de datos (GDPR).
                        </Checkbox>
                    </div>
                </div>
            </div>
        </motion.div>
    );
};

export default Step3;
