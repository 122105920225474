"use client";
import React from "react";
import { motion } from "framer-motion";
import Typography from "@/components/Typography";
import { Image } from "@nextui-org/image";

const animation = {
    exit: { opacity: 0 },
    initial: {
        opacity: 0,
        scale: 0.1,
    },
    animate: { opacity: 1, scale: 1 },
};

const SectionSeven: React.FC = () => {
    return (
        <section id="section-seven" className="my-32 xl:mt-72">
            <motion.div
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                transition={{ duration: 2 }}
            >
                <div className="container mx-auto flex flex-col lg:flex-row justify-center items-center py-5 px-2">
                    <div className="flex w-full xl:w-3/4 flex-col items-center">
                        <motion.div
                            {...animation}
                            transition={{ duration: 0.5, delay: 0.4 }}
                        >
                            <Typography
                                variant="h2"
                                className="text-white text-center text-4xl xl:text-7xl font-black"
                            >
                                ¡Ahora, hablemos de lo que más te importa!
                            </Typography>
                            <Typography
                                variant="body1"
                                className="text-white text-center pt-2.5 text-lg xl:text-2xl font-normal"
                            >
                                <strong>¿Preocupado por el precio?</strong> Es
                                natural sentir cierta inquietud al considerar
                                eventos de alta calidad como los nuestros.
                                Algunos podrían pensar que están fuera de su
                                alcance, pero espera un momento...
                            </Typography>
                        </motion.div>
                        <motion.div
                            {...animation}
                            transition={{ duration: 0.5, delay: 0.8 }}
                        >
                            <Image
                                alt=""
                                src="/images/image04.jpg"
                                height={675}
                                width={450}
                                className="drop-shadow-2xl my-10 xl:my-12"
                            />
                        </motion.div>
                        <motion.div
                            {...animation}
                            transition={{ duration: 0.5, delay: 1 }}
                        >
                            <Typography
                                variant="body1"
                                className="text-white text-center pt-2.5 text-lg xl:text-2xl font-normal"
                            >
                                Ahora, antes de que la preocupación se apodere
                                de ti, queremos explicarte algo importante.
                                Estamos comprometidos a brindarte un valor
                                excepcional por cada euro invertido. Nuestros
                                eventos no solo te ofrecen la oportunidad de
                                vender tus productos, sino que también te
                                conectan con un público apasionado y te brindan
                                visibilidad.
                            </Typography>
                            <Typography
                                variant="body1"
                                className="text-white text-center pt-2.5 text-xl xl:text-4xl font-bold"
                            >
                                Eso es lo que realmente importa.
                            </Typography>
                        </motion.div>
                        <motion.div
                            {...animation}
                            transition={{ duration: 0.5, delay: 1 }}
                        >
                            <Image
                                alt=""
                                src="/images/image05.jpg"
                                height={675}
                                width={450}
                                className="drop-shadow-2xl my-10 xl:my-12"
                            />
                        </motion.div>
                        <motion.div
                            {...animation}
                            transition={{ duration: 0.5, delay: 1.2 }}
                        >
                            <Typography
                                variant="body1"
                                className="text-white text-center pt-1 text-3xl xl:text-5xl font-bold"
                            >
                                Ya veo que tienes mejor cara.
                            </Typography>
                            <Typography
                                variant="body1"
                                className="text-white text-center pt-1 text-lg xl:text-2xl font-normal"
                            >
                                Pues te comentamos que el precio de reserva es
                                de{" "}
                                <strong className="relative">
                                    <span className="absolute -top-2 left-5 z-50 h-[50px] border-l-[3px] border-solid border-red -rotate-75"></span>
                                    <span className="relative inline-block">
                                        180€
                                    </span>
                                </strong>
                                , <br /> pero puede salirte en tan solo{" "}
                                <strong>120€</strong> o en menos.
                            </Typography>
                        </motion.div>
                        <motion.div
                            {...animation}
                            transition={{ duration: 0.5, delay: 1.4 }}
                        >
                            <Typography
                                variant="body1"
                                className="text-white text-center pt-20 text-2xl xl:text-5xl font-bold xl:!leading-[4rem]"
                            >
                                Si aún te parece caro mira todo lo que se
                                incluye por ese precio.
                            </Typography>
                            <ul className="pt-10 xl:pt-20 px-10 xl:px-40">
                                <li className="list-disc text-white text-left text-xl xl:text-2xl font-normal">
                                    5 días de actividades.
                                </li>
                                <li className="list-disc text-white text-left pt-5 text-xl xl:text-2xl font-normal">
                                    Decoración.
                                </li>
                                <li className="list-disc text-white text-left pt-5 text-xl xl:text-2xl font-normal">
                                    Ambientación.
                                </li>
                                <li className="list-disc text-white text-left pt-5 text-xl xl:text-2xl font-normal">
                                    Seguro de Responsabilidad Civil.
                                </li>
                                <li className="list-disc text-white text-left pt-5 text-xl xl:text-2xl font-normal">
                                    Suministro eléctrico
                                </li>
                                <li className="list-disc text-white text-left pt-5 text-xl xl:text-2xl font-normal">
                                    Seguridad nocturna.
                                </li>
                                <li className="list-disc text-white text-left pt-5 text-xl xl:text-2xl font-normal">
                                    Espectáculos de calle.
                                </li>
                                <li className="list-disc text-white text-left pt-5 text-xl xl:text-2xl font-normal">
                                    Animaciones y más sorpresas.
                                </li>
                                <li className="list-disc text-white text-left pt-5 text-xl xl:text-2xl font-normal">
                                    Publicidad a través de diferentes medios,
                                    incluidos redes sociales.
                                </li>
                                <li className="list-disc text-white text-left pt-5 text-xl xl:text-2xl font-normal">
                                    Bonos de descuentos para futuros eventos.
                                </li>
                            </ul>
                        </motion.div>
                        <motion.div
                            {...animation}
                            transition={{ duration: 0.5, delay: 1.2 }}
                        >
                            <Typography
                                variant="body1"
                                className="text-white text-center pt-20 text-3xl xl:text-5xl font-bold"
                            >
                                Importante.
                            </Typography>
                            <Typography
                                variant="body1"
                                className="text-white text-center pt-1 text-lg xl:text-2xl font-normal"
                            >
                                El precio de reserva se descuenta en su
                                totalidad del costo de los metros/lineales
                                necesarios para montar su puesto de ventas.
                            </Typography>
                        </motion.div>
                    </div>
                </div>
            </motion.div>
        </section>
    );
};

export default SectionSeven;
