import React from "react";
import createPersistedState from "use-persisted-state";

const useFormDirectionState = createPersistedState<string>("form-direction");

const useFormDirection = (): [
    string,
    React.Dispatch<React.SetStateAction<string>>,
] => {
    const [direction, setDirection] = useFormDirectionState("none");

    return [direction, setDirection];
};

export default useFormDirection;
