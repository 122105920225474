import React from "react";

import LogoWhiteSVG from "@/assets/svg/white.svg";

interface LogoProps {
    className: string;
}

const Logo: React.FC<LogoProps> = ({ className }) => {
    return <LogoWhiteSVG className={className} />;
};

export default Logo;
