"use client";
import React from "react";
import Link from "next/link";
import { motion } from "framer-motion";
import { Button } from "@nextui-org/button";
import Logo from "@/components/Logo";
import Typography from "@/components/Typography";

const animation = {
    exit: { opacity: 0 },
    initial: {
        opacity: 0,
        scale: 0.1,
    },
    animate: { opacity: 1, scale: 1 },
};

interface FooterProps {
    onPress: () => void;
}

const Footer: React.FC<FooterProps> = ({ onPress }) => {
    return (
        <section id="footer" className="mx-auto">
            <div className="flex flex-col items-center w-full py-5 px-2">
                <div className="flex flex-col justify-center w-full py-10 xl:py-20 max-w-5xl">
                    <motion.div
                        {...animation}
                        transition={{ duration: 0.5, delay: 0.4 }}
                    >
                        <Typography
                            variant="h2"
                            className="text-white text-center text-5xl xl:text-7xl font-black"
                        >
                            Pero aún hay más
                        </Typography>
                    </motion.div>
                    <motion.div
                        {...animation}
                        transition={{ duration: 0.5, delay: 0.8 }}
                    >
                        <Typography
                            variant="body1"
                            className="text-white text-center py-2.5 text-lg xl:text-2xl font-normal"
                        >
                            Por motivos de la celebración de nuestro 10°
                            aniversario estamos regalando un bono de descuento
                            de un <strong>20%</strong>. Pero solo para los primeros{" "}
                            <strong>30 artesanos, expositores o comerciantes</strong> que
                            contacten con nosotros.
                        </Typography>
                    </motion.div>
                    <motion.div
                        {...animation}
                        transition={{ duration: 0.5, delay: 1 }}
                    >
                        <Typography
                            variant="h3"
                            className="text-white text-center pt-5 text-xl xl:text-3xl font-semibold"
                        >
                            ¡Tu puedes ser uno de los afortunados!
                        </Typography>
                    </motion.div>
                </div>
                <motion.div
                    {...animation}
                    transition={{ duration: 0.5, delay: 1.2 }}
                >
                    <Button
                        size="lg"
                        radius="full"
                        onPress={onPress}
                        className="bg-lineal-gradient text-white py-8 xl:py-9 px-16 xl:px-20 text-lg xl:text-2xl font-bold uppercase drop-shadow-2xl"
                    >
                        {/* eslint-disable-next-line react/no-unescaped-entities */}
                        Únete, ven pa' AKA
                    </Button>
                </motion.div>
                <motion.div
                    {...animation}
                    transition={{ duration: 0.5, delay: 0.2 }}
                >
                    <Logo className="h-48 mt-36" />
                </motion.div>
                <motion.div
                    {...animation}
                    transition={{ duration: 0.5, delay: 0.6 }}
                >
                    <div className="flex flex-col-reverse md:flex-row gap-3 pt-12 md:divide-x-2">
                        <Typography
                            variant="body1"
                            className="text-white text-center text-sm pt-10 md:pt-0 font-semibold"
                        >
                            Copyright © 2023
                        </Typography>
                        <Link href="https://akaeventos.com/politica-privacidad/">
                            <Typography
                                variant="body1"
                                className="text-white text-center pl-2 text-sm font-semibold"
                            >
                                Políticas de Privacidad
                            </Typography>
                        </Link>
                        <Link href="https://akaeventos.com/politica-de-cookies/">
                            <Typography
                                variant="body1"
                                className="text-white text-center pl-2 text-sm font-semibold"
                            >
                                Políticas de Cookies
                            </Typography>
                        </Link>
                    </div>
                </motion.div>
            </div>
        </section>
    );
};

export default Footer;
