"use client";
import React from "react";
import { motion } from "framer-motion";
import { Image } from "@nextui-org/image";
import Typography from "@/components/Typography";

const animation = {
    exit: { opacity: 0 },
    initial: {
        opacity: 0,
        scale: 0.1,
    },
    animate: { opacity: 1, scale: 1 },
};

const SectionOne: React.FC = () => {
    return (
        <section id="section-one" className="container mx-auto my-32 xl:mt-72">
            <motion.div
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                transition={{ duration: 1.5 }}
            >
                <div className="flex flex-col lg:flex-row items-center p-5 gap-10">
                    <div className="flex w-full lg:w-1/2 flex-col">
                        <motion.div
                            {...animation}
                            transition={{ duration: 0.5, delay: 0.4 }}
                        >
                            <Typography
                                variant="h2"
                                className="text-left lg:text-right text-2xl xl:text-4xl font-bold text-rose-300"
                            >
                                ¿No encuentras lugares
                            </Typography>
                            <Typography
                                variant="h2"
                                className="text-left lg:text-right text-white text-3xl xl:text-5xl font-bold"
                            >
                                para vender tus creaciones?
                            </Typography>
                            <Typography
                                variant="body1"
                                className="text-left lg:text-right text-white py-2.5 text-lg xl:text-2xl font-normal"
                            >
                                En AKA Eventos, te ofrecemos la oportunidad de
                                exhibir y vender tus productos en nuestros
                                Mercados Medievales y eventos temáticos,
                                proporcionándote un entorno encantador y
                                auténtico para destacar.
                            </Typography>
                        </motion.div>
                    </div>
                    <div className="flex w-full lg:w-1/2">
                        <motion.div
                            {...animation}
                            initial={{
                                opacity: 0,
                                scale: 0.5,
                            }}
                            transition={{
                                duration: 0.3,
                                ease: [0, 0.71, 0.2, 1.01],
                                scale: {
                                    type: "spring",
                                    damping: 5,
                                    stiffness: 100,
                                    restDelta: 0.001,
                                },
                            }}
                            whileHover={{
                                rotate: 3,
                                scale: 1.05,
                                transition: { duration: 1 },
                            }}
                            whileTap={{ scale: 0.9 }}
                        >
                            <Image
                                isZoomed
                                width={560}
                                alt="¿No encuentras lugares para vender tus creaciones?"
                                src="/images/image01.jpg"
                                className="drop-shadow-2xl"
                            />
                        </motion.div>
                    </div>
                </div>
            </motion.div>
        </section>
    );
};

export default SectionOne;
