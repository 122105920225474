import React from "react";
import { Card, CardBody } from "@nextui-org/card";
import { Image } from "@nextui-org/image";
import Typography from "@/components/Typography";

interface TestimonialsProps {
    title: string;
    description: string;
    author: string;
    image: string;
}

const Testimonials: React.FC<TestimonialsProps> = ({
    title,
    description,
    author,
    image,
}) => {
    return (
        <Card
            isBlurred
            className="border-none mx-3 bg-background/60 dark:bg-default-100/50 max-w-[240px] md:max-w-[610px]"
            shadow="sm"
        >
            <CardBody>
                <div className="grid grid-cols-1 md:grid-cols-12 gap-0 md:gap-4 items-center justify-center">
                    <div className="relative col-span-12 md:col-span-4">
                        <Image
                            alt={title}
                            className="object-cover"
                            height={200}
                            shadow="md"
                            src={image}
                            width="100%"
                        />
                    </div>
                    <div className="flex flex-col col-span-12 md:col-span-8">
                        <div className="flex justify-between items-start">
                            <div className="flex flex-col gap-0">
                                <Typography
                                    variant="h3"
                                    className="text-black text-left pt-2.5 text-lg xl:text-xl font-semibold"
                                >
                                    {title}
                                </Typography>
                                <Typography
                                    variant="body1"
                                    className="text-black text-left pt-2.5 text-md xl:text-lg font-normal italic"
                                >
                                    {description}
                                </Typography>
                                <div className="flex flex-col md:flex-row justify-between">
                                    <Typography
                                        variant="body1"
                                        className="text-black text-left pt-2.5 text-sm xl:text-md font-normal italic"
                                    >
                                        {author}
                                    </Typography>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </CardBody>
        </Card>
    );
};

export default Testimonials;
