import React from "react";
import { motion } from "framer-motion";
import { Button } from "@nextui-org/button";
import { Modal, ModalBody, ModalContent, ModalHeader } from "@nextui-org/modal";
import Typography from "@/components/Typography";

interface SuccessMessageProps {
    isOpen: boolean;
    onOpenChange: () => void;
    onCloseParent: () => void;
}

const SuccessMessage: React.FC<SuccessMessageProps> = ({
    isOpen,
    onOpenChange,
    onCloseParent,
}) => {
    const animation = {
        exit: { opacity: 0 },
        initial: {
            opacity: 0,
            scale: 0.1,
        },
        animate: { opacity: 1, scale: 1 },
    };

    return (
        <motion.div {...animation} transition={{ duration: 0.5 }}>
            <Modal
                isOpen={isOpen}
                hideCloseButton
                onOpenChange={onOpenChange}
                isDismissable={false}
                size="2xl"
                backdrop="blur"
            >
                <ModalContent className="flex self-center bg-radial-gradient-bottom drop-shadow-2xl p-5 max-h-full lg:max-h-[calc(100%_-_7.5rem)]">
                    {(onClose) => (
                        <>
                            <ModalHeader className="flex flex-col gap-1 text-white">
                                <Typography
                                    variant="h2"
                                    className="text-white text-center text-2xl md:text-4xl font-black"
                                >
                                    ¡Muy bien!
                                </Typography>
                            </ModalHeader>
                            <ModalBody className="flex items-center w-full">
                                <Typography
                                    variant="body1"
                                    className="text-white text-center text-lg md:text-xl font-black"
                                >
                                    Hemos recibido su inscripción
                                    satisfactoriamente. Espere respuestas
                                    nuestras.
                                </Typography>
                                <Button
                                    size="lg"
                                    radius="full"
                                    onPress={() => {
                                        onClose();
                                        setTimeout(() => {
                                            onCloseParent();
                                        }, 100);
                                    }}
                                    className="bg-lineal-gradient text-white mt-10 py-8 px-16 text-xl font-bold uppercase drop-shadow-2xl"
                                >
                                    Cerrar
                                </Button>
                            </ModalBody>
                        </>
                    )}
                </ModalContent>
            </Modal>
        </motion.div>
    );
};

export default SuccessMessage;
