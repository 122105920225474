import React from "react";
import { FormikProps, FormikValues } from "formik";
import { motion } from "framer-motion";
import { Input, Textarea } from "@nextui-org/input";
import { Select, SelectItem } from "@nextui-org/select";
import { Radio, RadioGroup } from "@nextui-org/radio";
import { useFormDirection } from "@/modules/persistent-states";

const categories = [
    { label: "Artesanía en Alimentos", value: "artesania-en-alimentos" },
    { label: "Artesanía en Cuero", value: "artesania-en-cuero" },
    { label: "Artesanía en Madera", value: "artesania-en-madera" },
    { label: "Artesanía en Metal", value: "artesania-en-metal" },
    {
        label: "Artesanía en Papel y Cartón",
        value: "artesania-en-papel-y-carton",
    },
    { label: "Artesanía en Piedra", value: "artesania-en-piedra" },
    {
        label: "Artesanía en Plantas y Flores",
        value: "artesania-en-plantas-y-flores",
    },
    { label: "Artesanía en Tela", value: "artesania-en-tela" },
    { label: "Artesanía en Vidrio", value: "artesania-en-vidrio" },
    { label: "Cerámica y Alfarería", value: "ceramica-y-alfareria" },
    { label: "Textiles y Ropa", value: "textiles-y-ropa" },
    { label: "Joyería", value: "joyeria" },
    { label: "Joyería en plata", value: "joyeria-en-plata" },
    { label: "Restauración", value: "restauracion" },
    { label: "Alimentación al Vacío", value: "alimentacion-al-vacio" },
    { label: "Talleres", value: "talleres" },
    {
        label: "Otros",
        value: "otros",
    },
];

const Step2: React.ComponentType<
    FormikProps<FormikValues> & {
        currentStepIndex: number;
    }
> = ({ values, touched, errors, handleChange, handleBlur }) => {
    const [direction] = useFormDirection();

    let animation;

    if (direction === "ltr") {
        animation = {
            initial: {
                x: "-50%",
            },
            animate: { x: 0 },
        };
    }

    if (direction === "rtl") {
        animation = {
            initial: {
                x: "50%",
            },
            animate: { x: 0 },
        };
    }

    return (
        <motion.div {...animation} transition={{ duration: 0.5 }}>
            <div className="flex justify-center items-center flex-col w-full">
                <div className="flex justify-center items-center flex-col gap-3 w-full">
                    <div className="flex w-full lg:h-[75px]">
                        <Input
                            name="business_name"
                            type="text"
                            label="Nombre del Negocio"
                            isRequired
                            value={values?.business_name}
                            errorMessage={
                                <>
                                    {touched?.business_name &&
                                        errors?.business_name}
                                </>
                            }
                            color={
                                touched?.business_name && errors?.business_name
                                    ? "danger"
                                    : "default"
                            }
                            onChange={handleChange}
                            onBlur={handleBlur}
                            classNames={{
                                label: "font-bold",
                                errorMessage: "font-bold text-rose-200",
                            }}
                        />
                    </div>
                    <div className="flex w-full lg:h-[75px]">
                        <Select
                            items={categories}
                            name="category"
                            label="Categoría"
                            isRequired
                            selectedKeys={
                                values?.category ? [values?.category] : []
                            }
                            value={values?.category}
                            errorMessage={
                                <>{touched?.category && errors?.category}</>
                            }
                            color={
                                touched?.category && errors?.category
                                    ? "danger"
                                    : "default"
                            }
                            onChange={handleChange}
                            onBlur={handleBlur}
                            classNames={{
                                label: "font-bold",
                                errorMessage: "font-bold text-rose-200",
                            }}
                        >
                            {(category: { label: string; value: string }) => (
                                <SelectItem
                                    key={category.value}
                                    value={category.value}
                                >
                                    {category.label}
                                </SelectItem>
                            )}
                        </Select>
                    </div>
                    <div className="flex w-full lg:h-[75px]">
                        <RadioGroup
                            label="¿Cuál es tu tipo de entidad?"
                            orientation="horizontal"
                            name="business_type"
                            isRequired
                            value={values?.business_type || "ninguno"}
                            errorMessage={
                                <>
                                    {touched?.business_type &&
                                        errors?.business_type}
                                </>
                            }
                            color={
                                touched?.business_type && errors?.business_type
                                    ? "danger"
                                    : "default"
                            }
                            onChange={handleChange}
                            onBlur={handleBlur}
                            classNames={{
                                label: "font-montserrat font-bold !text-white",
                                errorMessage: "font-bold text-rose-200",
                            }}
                        >
                            <Radio value="ninguno" color="danger">
                                Ninguno
                            </Radio>
                            <Radio value="autonomo" color="danger">
                                Autónomo
                            </Radio>
                            <Radio value="empresa" color="danger">
                                Empresa
                            </Radio>
                        </RadioGroup>
                    </div>
                    <div className="flex w-full h-[155px] mb-[0.95rem]">
                        <Textarea
                            name="description"
                            type="text"
                            label="Descripción breve sobre sus productos (Opcional)"
                            value={values?.description}
                            size="lg"
                            minRows={4}
                            errorMessage={
                                <>
                                    {touched?.description &&
                                        errors?.description}
                                </>
                            }
                            color={
                                touched?.description && errors?.description
                                    ? "danger"
                                    : "default"
                            }
                            onChange={handleChange}
                            onBlur={handleBlur}
                            classNames={{
                                label: "font-bold",
                                errorMessage: "font-bold text-rose-200",
                            }}
                        />
                    </div>
                </div>
            </div>
        </motion.div>
    );
};

export default Step2;
