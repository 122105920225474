import React from "react";
import { Modal, ModalContent, ModalHeader, ModalBody } from "@nextui-org/modal";
import EnrollmentForm from "@/forms/enrollment-form";
import Typography from "@/components/Typography";
import { useFormDirection } from "@/modules/persistent-states";

interface ModalFormProps {
    isOpen: boolean;
    onOpenChange: () => void;
}

const ModalForm: React.FC<ModalFormProps> = ({ isOpen, onOpenChange }) => {
    const [, setDirection] = useFormDirection();

    return (
        <Modal
            isOpen={isOpen}
            isDismissable={false}
            onOpenChange={onOpenChange}
            size="2xl"
            scrollBehavior="normal"
            backdrop="blur"
            onClose={() => {
                setDirection("none");
            }}
            classNames={{
                closeButton: "text-white text-xl lg:text-3xl hover:text-black",
            }}
        >
            <ModalContent className="flex self-start bg-radial-gradient-modal drop-shadow-2xl p-2 lg:p-5">
                {(onClose) => (
                    <>
                        <ModalHeader className="flex flex-col gap-1 text-white">
                            <Typography
                                variant="h2"
                                className="text-white text-left text-md md:text-2xl font-black"
                            >
                                Formulario de Inscripción
                            </Typography>
                        </ModalHeader>
                        <ModalBody className="overflow-hidden">
                            <EnrollmentForm onClose={onClose} />
                        </ModalBody>
                    </>
                )}
            </ModalContent>
        </Modal>
    );
};

export default ModalForm;
